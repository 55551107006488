import React from "react";
import { makeStyles } from "@material-ui/core";


export interface YoutubeProps {
	code: string,
}

export const EmbedYoutube = React.memo((props: YoutubeProps) => {
	const { code } = props;
	const classes = useStyles();

	return (
		<div className={classes.wrapper}>
			<iframe
				src={"https://www.youtube-nocookie.com/embed/" + code}
				className={classes.iframe}
				width="100%"
				height="100%"
				frameBorder="0"
				allowFullScreen
				allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
				title="Youtube Video"
			/>
		</div>
	);
});


const useStyles = makeStyles(theme => ({
	wrapper: {
		position: "relative",
		paddingBottom: "56.25%",
		paddingTop: 3,
		height: 0,
		overflow: "hidden",
	},
	iframe: {
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
	},
}));
